<template>
  <div class="faq">
    <page-header title="FAQ" :has-back="true" @back="goBack" />

    <div class="md-layout md-alignment-center-center">
      <md-card class="faq__card md-layout-item md-size-80">
        <md-card-content>
          <h3 class="faq__card-title">
            <p>{{ $t("faq.title1") }}</p>
            <p>{{ $t("faq.title2") }}</p>
          </h3>
          <md-list :md-expand-single="false">
            <md-list-item
              v-for="(question, index) of questions"
              :key="index"
              md-expand
            >
              <span class="md-list-item-text">{{ question.label }}</span>

              <div slot="md-expand" class="faq__expand">
                <div
                  v-for="(block, blockIndex) of question.description"
                  :key="blockIndex"
                >
                  <div v-if="block.label && block.label.length > 0">
                    <p class="faq__expand-title">{{ block.label }}</p>

                    <p>{{ block.description }}</p>
                  </div>

                  <p v-else-if="block.hasContactButton">
                    <i18n :path="block.description" tag="span">
                      <template #contact>
                        <router-link to="/dashboard/contact/">
                          {{ $t("faq.contact") }}
                        </router-link>
                      </template>
                    </i18n>
                  </p>

                  <p v-else>
                    {{ block }}
                  </p>
                </div>
              </div>
            </md-list-item>
          </md-list>

          <p class="faq__card-title">
            {{ $t("faq.answer-not-found") }}
          </p>

          <p class="faq__card-title faq__card-title--red">
            {{ $t("faq.contact-us") }}
          </p>

          <div class="md-layout md-alignment-center-center">
            <md-button class="md-primary" to="/dashboard/contact/">
              {{ $t("faq.cta") }}</md-button
            >
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import { mapGetters } from "vuex";

export default {
  name: "FAQ",

  components: {
    PageHeader,
  },

  mixins: [HasBackMixin],

  data() {
    return {
      questions: [
        {
          label: this.$t("faq.create-user.label"),
          description: [this.$t("faq.create-user.description")],
        },
        {
          label: this.$t("faq.assign-user.label"),
          description: [
            {
              label: this.$t("faq.assign-user.description.before-create.label"),
              description: this.$t(
                "faq.assign-user.description.before-create.description"
              ),
            },
            {
              label: this.$t("faq.assign-user.description.after-create.label"),
              description: this.$t(
                "faq.assign-user.description.after-create.description"
              ),
            },
          ],
        },
        {
          label: this.$t("faq.group.label"),
          description: [this.$t("faq.group.description")],
        },
        {
          label: this.$t("faq.push-notification.label"),
          description: [this.$t("faq.push-notification.description")],
        },
        {
          label: this.$t("faq.cloud.label"),
          description: [
            this.$t("faq.cloud.description1"),
            this.$t("faq.cloud.description2"),
            this.$t("faq.cloud.description3"),
          ],
        },
        {
          label: this.$t("faq.news.label"),
          description: [this.$t("faq.news.description")],
        },
        {
          label: this.$t("faq.delete-news.label"),
          description: [
            this.$t("faq.delete-news.description1"),
            this.$t("faq.delete-news.description2"),
          ],
        },
        {
          label: this.$t("faq.restore-news.label"),
          description: [this.$t("faq.restore-news.description")],
        },
        {
          label: this.$t("faq.application-price.label"),
          description: [
            {
              description: "faq.application-price.description",
              hasContactButton: true,
            },
          ],
        },
        {
          label: this.$t("faq.application-difficult.label"),
          description: [
            {
              description: "faq.application-difficult.description",
              hasContactButton: true,
            },
          ],
        },
        {
          label: this.$t("faq.aplication-thirdparty.label"),
          description: [this.$t("faq.aplication-thirdparty.description")],
        },
        {
          label: this.$t("faq.undesired-function.label"),
          description: [this.$t("faq.undesired-function.description")],
        },
        {
          label: this.$t("faq.new-function.label"),
          description: [this.$t("faq.new-function.description")],
        },
        {
          label: this.$t("faq.design.label"),
          description: [this.$t("faq.design.description")],
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["isMEEP"]),
  },

  mounted() {
    const justMeepItems = [
      "application-price",
      "application-difficult",
      "aplication-thirdparty",
      "undesired-function",
      "new-function",
      "design",
    ].map((item) => this.$t(`faq.${item}.label`));
    if (!this.isMEEP) {
      this.questions = this.questions.filter(
        (item) => !justMeepItems.includes(item.label)
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.faq {
  &__expand {
    height: auto;
    white-space: normal;
    font-size: 0.8rem;
    padding: 0 16px;
  }

  &__expand-title {
    font-style: italic;
  }

  &__card-title {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    p {
      margin: 0 auto;
    }
    &--red {
      color: var(--primary);
    }
  }
}
</style>
